import appColors from "@/Utils/appColors";
import React from "react";

export const FeedbackSvg = () => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="88"
        height="88"
        rx="44"
        fill={appColors.primary}
        fillOpacity="0.1"
      />
      <path
        d="M60 44.4V47.8C60 54.1 56.4 56.8 51 56.8H33C27.6 56.8 24 54.1 24 47.8V37C24 30.7 27.6 28 33 28H38.4C38.14 28.76 38 29.6 38 30.5V38.3C38 40.24 38.64 41.88 39.78 43.02C40.92 44.16 42.56 44.8 44.5 44.8V47.58C44.5 48.6 45.66 49.22 46.52 48.66L52.3 44.8H57.5C58.4 44.8 59.24 44.66 60 44.4Z"
        fill={appColors.primary}
      />
      <path
        opacity="0.4"
        d="M64 30.5V38.3C64 41.28 62.48 43.52 60 44.4C59.24 44.66 58.4 44.8 57.5 44.8H52.3L46.52 48.66C45.66 49.22 44.5 48.6 44.5 47.58V44.8C42.56 44.8 40.92 44.16 39.78 43.02C38.64 41.88 38 40.24 38 38.3V30.5C38 29.6 38.14 28.76 38.4 28C39.28 25.52 41.52 24 44.5 24H57.5C61.4 24 64 26.6 64 30.5Z"
        fill={appColors.primary}
      />
      <path
        opacity="0.4"
        d="M49.1998 62.5H43.4998V56.8C43.4998 55.98 42.8198 55.3 41.9998 55.3C41.1798 55.3 40.4998 55.98 40.4998 56.8V62.5H34.7998C33.9798 62.5 33.2998 63.18 33.2998 64C33.2998 64.82 33.9798 65.5 34.7998 65.5H49.1998C50.0198 65.5 50.6998 64.82 50.6998 64C50.6998 63.18 50.0198 62.5 49.1998 62.5Z"
        fill={appColors.primary}
      />
      <path
        d="M50.8799 36C50.0399 36 49.3799 35.32 49.3799 34.5C49.3799 33.68 50.0599 33 50.8799 33C51.6999 33 52.3799 33.68 52.3799 34.5C52.3799 35.32 51.6999 36 50.8799 36Z"
        fill={appColors.primary}
      />
      <path
        d="M56.5 36C55.66 36 55 35.32 55 34.5C55 33.68 55.68 33 56.5 33C57.32 33 58 33.68 58 34.5C58 35.32 57.32 36 56.5 36Z"
        fill={appColors.primary}
      />
      <path
        d="M45.2402 36C44.4002 36 43.7402 35.32 43.7402 34.5C43.7402 33.68 44.4202 33 45.2402 33C46.0602 33 46.7402 33.68 46.7402 34.5C46.7402 35.32 46.0802 36 45.2402 36Z"
        fill={appColors.primary}
      />
    </svg>
  );
};

export const FeedbackNextRow = ({ color }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4302 0.930176L18.5002 7.00018L12.4302 13.0702"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 7H18.33"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
