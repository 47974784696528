"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import CartPhone from "./CartPhone";
import { useDispatch, useSelector } from "react-redux";
import { logOutServices } from "@/services/userServices";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { setSelectedTable } from "@/redux/slices/sectionsSlice";
import {
  setAddressType,
  setWithoutDelivery,
} from "@/redux/slices/addressSlice";
import { removeUserData } from "@/redux/slices/authSlice";
import { toast } from "react-toastify";
import { ScannerSvg } from "@/svgs/scannerSvg";
import { CartSvg } from "@/svgs/cartSvg";
import appColors from "@/Utils/appColors";
import { NotificationSvg } from "@/svgs/notification";
import { useTranslation } from "@/Utils/lanHook";

const HeaderPh = () => {
  const pathName = usePathname();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const { selectedTable } = useSelector((state) => state.sections);
  const { isAuth, user } = useSelector((state) => state.auth);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const [showOptions, setShowOptions] = useState(false);
  const router = useRouter();

  const tableId = searchParams.get("restaurant_table_id");
  const orderType = searchParams.get("order_type");

  useEffect(() => {
    if (tableId) {
      dispatch(setSelectedTable(tableId));
      dispatch(setAddressType(orderType));
      dispatch(
        orderType === "delivery"
          ? setWithoutDelivery(true)
          : setWithoutDelivery(false)
      );
    }
  }, [tableId, dispatch, orderType]);

  const toggleCartPopup = () => {
    setIsCartPopupOpen(!isCartPopupOpen);
  };

  const closeCartPopup = () => {
    setIsCartPopupOpen(false);
  };

  const handleLogout = async () => {
    await logOutServices()
      .then(() => dispatch(removeUserData()))
      .then(() => router.replace("/"))
      .then(() => toast.success("تم تسجيل الخروج بنجاح"));
  };

  if (pathName === "/404" || pathName === "/delivery-details") {
    return null;
  }

  return (
    <div className="fixed bottom-10 left-0 md:left-1/4 flex z-[51] justify-center items-center">
      <div className="w-[377px]  h-[58px] p-2 bg-primary rounded-[22px] shadow flex items-center justify-center gap-2">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
          {selectedTable !== null ? (
            <div className="pl-1.5 pr-4 py-1.5 bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-2.5 flex">
              {selectedTable?.table_number || "Table number: " + selectedTable}
            </div>
          ) : (
            <div
              onClick={() => router.push("/tables")}
              className="pl-[3px] pr-2 py-[3px] bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-2 inline-flex cursor-pointer"
            >
              <div className="w-9 h-9 relative bg-primary rounded-full shadow">
                <div className="w-[18px] h-[18px] left-[5px] top-[9px] absolute">
                  <div className="w-[18px] h-[18px] left-0 top-[-0px] absolute mx-1">
                    <Image width={24} height={24} src="/noun.svg" alt="image" />
                  </div>
                </div>
              </div>
              <div className="text-black text-sm font-medium font-['K2D'] leading-[16.84px]">
                {t("nav.bookTable")}
              </div>
            </div>
          )}
        </div>
        <div className="p-[5px] bg-white rounded-[28px] justify-center items-center gap-2.5 flex">
          <ScannerSvg className="w-[32px] h-[32px]" />
        </div>

        <div className="h-[42px] justify-start items-center gap-1.5 inline-flex">
          <div
            onClick={toggleCartPopup}
            className="p-[3px] bg-white rounded-[100px] justify-start items-center gap-2.5 flex"
          >
            <div className="pl-2 pr-2.5 py-2 rounded-[100px] border border-[#dce8e9] justify-center items-center gap-1.5 flex">
              <CartSvg color={appColors.primary} />
              {cart.length > 0 ? (
                <div className="text-center text-primary text-base font-medium leading-tight">
                  {cart.length}
                </div>
              ) : null}
            </div>
          </div>
          {isCartPopupOpen && <CartPhone onClose={closeCartPopup} />}

          {isAuth ? (
            <div className="grow shrink  basis-0 flex-col justify-center items-end gap-2 inline-flex">
              <div className="justify-start items-center gap-2.5 inline-flex">
                <div className="p-[4px] h-[42px] bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-1.5 flex">
                  <div className="w-9 h-9 justify-center items-center gap-2.5 flex">
                    <div className="w-9 h-9 p-2 rounded-[100px] justify-center items-center gap-1.5 flex">
                      <NotificationSvg className="w-[20px] h-[20px]" />
                    </div>
                  </div>
                  <div
                    onClick={() => setShowOptions(!showOptions)}
                    className="w-9 h-9 justify-center items-center gap-2.5 flex relative"
                  >
                    <div className="grow shrink basis-0 self-stretch p-2 bg-primary/10 rounded-[100px] justify-center items-center gap-2.5 flex">
                      <div className="text-center text-primary text-base font-medium leading-tight">
                        {user?.name?.substring(0, 2).toUpperCase()}
                      </div>
                    </div>
                    {showOptions && (
                      <div className="absolute w-24 h-12 text-center items-center left-[-40px] bg-black rounded-xl text-white top-[-50px]">
                        <h1 onClick={handleLogout}>{t("nav.logout")}</h1>
                        <h1
                          onClick={() => {
                            router.push("/profile");
                            setShowOptions(false);
                          }}
                        >
                          {t("nav.profile")}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Link href={"/log-in"}>
              <div className="p-[3px] bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-1.5 flex">
                <div className="px-[7px] py-2.5 bg-primary rounded-[100px] justify-center items-center gap-2.5 flex">
                  <div className="text-white text-base font-normal leading-tight">
                    {t("nav.auth.register")}
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderPh;
