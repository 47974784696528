"use client";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { HashLoader } from "react-spinners";

const Spinner = ({ text }) => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  useEffect(() => {
    const preventScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    if (isLoading) {
      // Prevent scrolling by adding event listeners
      window.addEventListener("scroll", preventScroll, { passive: false });
      window.addEventListener("wheel", preventScroll, { passive: false });
      window.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      // Re-enable scrolling by removing event listeners
      window.removeEventListener("scroll", preventScroll);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    }

    // Cleanup function to remove listeners when component unmounts
    return () => {
      window.removeEventListener("scroll", preventScroll);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div className="wrapper">
      <div className="overlay"></div>
      <div className="loader">
        <HashLoader color="#eeeeee" size={80} />
        {text && <p className="loadingText">{text}</p>}
      </div>
    </div>
  );
};

export default Spinner;
