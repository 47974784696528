"use client";

import { useEffect, useState } from "react";
import Header from "@/components/Header";
import HeaderPh from "@/components/HeaderTest";
import HeaderPhon from "@/components/HeaderPhon";
import SetAccountSettings from "@/components/setAccountSettings";
import AuthInitializer from "@/app/authInitializer";
import Spinner from "@/components/spinner";
import FeedBack from "@/components/feedBack";
import Footer from "@/components/footer";
import DirectionWrapper from "@/components/directionWrapper";
import ToastProvider from "@/components/toastProvider";
import LandingPage from "@/app/landing/page";
import { getSubdomain } from "@/Utils/commons";

export default function SubdomainLayout({ children }) {
  const [subDomain, setSubDomain] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const host = getSubdomain();

    if (host) {
      setSubDomain(host);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner text={"Loading..."} />
      </div>
    );
  }

  return (
    <>
      {subDomain ? (
        <main className="max-w-[1540px] mx-auto">
          <SetAccountSettings />
          <div className="px-8">
            <div className="hidden lg:flex w-full">
              <Header />
            </div>
            <div className="flex lg:hidden">
              <HeaderPhon />
              <HeaderPh />
            </div>
          </div>
          <AuthInitializer>
            <Spinner text={"Loading..."} />
            <DirectionWrapper>
              <ToastProvider>
                <FeedBack />
                <div className="mb-16">{children}</div>
                <Footer />
              </ToastProvider>
            </DirectionWrapper>
          </AuthInitializer>
        </main>
      ) : (
        <DirectionWrapper>
          <ToastProvider>
            <LandingPage />
          </ToastProvider>
        </DirectionWrapper>
      )}
    </>
  );
}
