import appColors from "@/Utils/appColors";

export const NotificationSvg = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.0166 2.42499C7.25828 2.42499 5.01662 4.66665 5.01662 7.42499V9.83332C5.01662 10.3417 4.79995 11.1167 4.54162 11.55L3.58328 13.1417C2.99162 14.125 3.39995 15.2167 4.48328 15.5833C8.07495 16.7833 11.95 16.7833 15.5416 15.5833C16.55 15.25 16.9916 14.0583 16.4416 13.1417L15.4833 11.55C15.2333 11.1167 15.0166 10.3417 15.0166 9.83332V7.42499C15.0166 4.67499 12.7666 2.42499 10.0166 2.42499Z"
      stroke={appColors.primary}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.5583 2.66664C11.3 2.59164 11.0333 2.5333 10.7583 2.49997C9.95831 2.39997 9.19164 2.4583 8.47498 2.66664C8.71664 2.04997 9.31664 1.61664 10.0166 1.61664C10.7166 1.61664 11.3166 2.04997 11.5583 2.66664Z"
      stroke={appColors.primary}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5167 15.8834C12.5167 17.2584 11.3917 18.3834 10.0167 18.3834C9.33339 18.3834 8.70006 18.1 8.25006 17.65C7.80006 17.2 7.51672 16.5667 7.51672 15.8834"
      stroke={appColors.primary}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
