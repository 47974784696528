import appColors from "@/Utils/appColors";

export const ScannerSvg = ({ className }) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M27.1666 16H5.83325"
      stroke={appColors.primary}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8333 5.33301H24.4999C25.9727 5.33301 27.1666 6.52691 27.1666 7.99967V10.6663"
      stroke={appColors.primary}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1666 26.6664H8.49992C7.02716 26.6664 5.83325 25.4724 5.83325 23.9997V21.3331"
      stroke={appColors.primary}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1666 21.3331V23.9997C27.1666 25.4724 25.9727 26.6664 24.4999 26.6664H21.8333"
      stroke={appColors.primary}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83325 10.6663V7.99967C5.83325 6.52691 7.02716 5.33301 8.49992 5.33301H11.1666"
      stroke={appColors.primary}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
