"use client";

import { useEffect, useState } from "react";
import { getSubdomain } from "@/Utils/commons";
import { store } from "@/redux/store";

const DynamicTitle = () => {
  const [subDomain, setSubDomain] = useState(null);
  const [account, setAccount] = useState(null);
  useEffect(() => {
    const domain = getSubdomain();
    const accountSetteing = store.getState().accountPages.accountSettings;
    setSubDomain(domain);
    setAccount(accountSetteing);
  }, []);

  const title = subDomain ? `${subDomain.toUpperCase()} - Menu` : "E - Menu";

  return (
    <head>
      <title>{title}</title>
      <link
        rel="icon"
        href={account?.logo ?? "Logo.png"}
        type="image/png"
        sizes="50x50"
        alt="E - Menu"
      />
    </head>
  );
};

export default DynamicTitle;
