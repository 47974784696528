"use client";

import Spinner from "@/components/spinner";
import { fetchUserFromStorage } from "@/redux/slices/authSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AuthInitializer = ({ children }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Dispatch action to load user data from secure storage on app load
    const initAuth = async () => {
      dispatch(fetchUserFromStorage());
    };

    initAuth().then(() => setIsInitialized(true));
  }, [dispatch]);

  if (!isInitialized) {
    return <Spinner text="Loading ..." />;
  } else {
    return <>{children}</>;
  }
};

export default AuthInitializer;
