"use client";

import { setAccountSettings } from "@/redux/slices/accountSlice";
import { store } from "@/redux/store";
import { fetchAccountSettings } from "@/services/accountServices";
import { hexToRgb, primaryColor } from "@/Utils/appColors";
import { getSubdomain } from "@/Utils/commons";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const SetAccountSettings = () => {
  const router = useRouter();
  const subDomain = getSubdomain();

  const dispatch = useDispatch();
  useEffect(() => {
    fetchAccountSettings()
      .then((data) => dispatch(setAccountSettings(data)))
      .catch((error) =>
        console.error("Error fetching account settings:", error)
      );

    if (typeof window !== "undefined") {
      const primary =
        JSON.parse(localStorage.getItem("accountSettings"))?.primary_color ||
        primaryColor;
      const rgbColor = hexToRgb(primary);

      document.documentElement.style.setProperty("--primary-color", rgbColor);
    }
  }, [dispatch]);

  return null;
};

export default SetAccountSettings;
