"use client";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackSvg, FeedbackNextRow } from "@/svgs/feedbackSvg";
import {
  fetchFeedbacksQuistions,
  submitFeedback,
} from "@/services/feedbackService";
import {
  setFeedbacks,
  setActiveFeedbackId,
  setCurrentQuestionIndex,
} from "@/redux/slices/feedbacksSlice";

export default function FeedBack() {
  const dispatch = useDispatch();
  const { feedbacks } = useSelector((state) => state.feedbacks || []);
  const { activeFeedbackId } = useSelector((state) => state.feedbacks || null);

  const handleFeedbackClick = (feedbackId) => {
    dispatch(setActiveFeedbackId(feedbackId));
  };

  return (
    <>
      {feedbacks?.length > 0 &&
        feedbacks?.map((feedback, index) =>
          feedback?.questions?.length > 0 ? (
            <button
              key={feedback?.id}
              onClick={() => handleFeedbackClick(feedback?.id)}
              className="fixed right-0 transform -rotate-90 origin-bottom-right text-white bg-primary rounded-xl p-2 leading-3"
              style={{ top: `${25 + index * 10}%` }}
            >
              {feedback?.title}
            </button>
          ) : null
        )}

      {feedbacks?.map(
        (feedback) =>
          activeFeedbackId === feedback?.id && (
            <FeedBackSheet key={feedback?.id} feedback={feedback} />
          )
      )}
    </>
  );
}

const FeedBackSheet = ({ feedback }) => {
  const dispatch = useDispatch();
  const { currentQuestionIndex } = useSelector(
    (state) => state.feedbacks || -1
  );

  const currentQuestion = feedback?.questions[currentQuestionIndex];

  console.log("currentQuestion", currentQuestion);

  const handleNext = () => {
    dispatch(setCurrentQuestionIndex(0));
  };

  const handleAnswerClick = async (answerId) => {
    await submitFeedback({
      question_value_id: answerId,
    });

    if (currentQuestionIndex < feedback?.questions?.length - 1) {
      setTimeout(() => {
        dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
      }, 300);
    } else {
      setTimeout(() => dispatch(setActiveFeedbackId(null)), 300);
      fetchFeedbacksQuistions()
        .then((data) => dispatch(setFeedbacks(data)))
        .catch((error) => console.error("Error fetching feedbacks:", error));
    }
  };

  return (
    <motion.div
      className="fixed top-1/4 right-3 w-1/4 bg-white shadow-2xl p-8 rounded-xl z-50"
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 100, opacity: 0 }}
    >
      <div className="grid place-items-center text-center max-h-96 overflow-y-auto scrollbar-hide">
        <FeedbackSvg />

        {currentQuestionIndex === -1 ? (
          <>
            <h1 className="mb-4 font-bold">{feedback?.title}</h1>
            <p>{feedback.description || "No description available"}</p>
          </>
        ) : (
          <motion.div
            key={currentQuestion?.id}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
          >
            <h1 className="mb-4 font-bold">{currentQuestion?.question_text}</h1>
            <div className="mt-4">
              {currentQuestion?.answer.map((answer) => (
                <button
                  key={answer?.id}
                  onClick={() => handleAnswerClick(answer?.id)}
                  className="bg-primary text-white p-2 m-1 rounded"
                >
                  {answer?.value}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </div>

      <div className="flex justify-end gap-2 mt-2">
        <button
          onClick={() => dispatch(setActiveFeedbackId(null))}
          type="button"
          className="bg-gray-100 p-3 rounded-xl"
        >
          Close
        </button>
        {currentQuestionIndex === -1 && (
          <button
            onClick={handleNext}
            type="button"
            className="bg-primary text-white p-3 rounded-xl flex items-center gap-2"
          >
            Next <FeedbackNextRow />
          </button>
        )}
      </div>
    </motion.div>
  );
};
