"use client";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function DirectionWrapper({ children }) {
  const language = useSelector((state) => state.language);

  useEffect(() => {
    // Set the direction attribute on the body element
    document.body.setAttribute("dir", language === "ar" ? "rtl" : "ltr");
  }, [language]);

  return <>{children}</>;
}
