"use client";

import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { SearchNormal } from "iconsax-react";
import Image from "next/image";
import Link from "next/link";
import CartPopup from "./CartPopup";
import { useDispatch, useSelector } from "react-redux";
import { logOutServices } from "@/services/userServices";
import { CartSvg } from "@/svgs/cartSvg";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { setSelectedTable } from "@/redux/slices/sectionsSlice";
import {
  setAddressType,
  setWithoutDelivery,
} from "@/redux/slices/addressSlice";
import { removeUserData } from "@/redux/slices/authSlice";
import { toast } from "react-toastify";
import { useLanguage, useTranslation } from "@/Utils/lanHook";

function Header() {
  const { language, setReduxLanguage } = useLanguage();
  const { t } = useTranslation();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const { selectedTable } = useSelector((state) => state.sections || null);
  const { accountSettings } = useSelector(
    (state) => state.accountPages || null
  );
  const [focused, setFocused] = useState(false);
  const { isAuth, user } = useSelector((state) => state.auth);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const router = useRouter();

  const handleSearchClick = () => {
    if (searchTerm) {
      router.push(`/?search=${encodeURIComponent(searchTerm)}`);
    } else {
      setSearchTerm("");
      router.push("/");
    }
  };

  const tableId = searchParams.get("restaurant_table_id");
  const orderType = searchParams.get("order_type");

  useEffect(() => {
    if (tableId) {
      dispatch(setSelectedTable(tableId));
      dispatch(setAddressType(orderType));
      dispatch(
        orderType === "delivery"
          ? setWithoutDelivery(true)
          : setWithoutDelivery(false)
      );
    }
  }, [tableId, dispatch, orderType]);

  const toggleCartPopup = () => {
    setIsCartPopupOpen(!isCartPopupOpen);
  };

  const closeCartPopup = () => {
    setIsCartPopupOpen(false);
  };

  const handleLogout = async () => {
    await logOutServices()
      .then(() => dispatch(removeUserData()))
      .then(() => router.replace("/"))
      .then(() => toast.success("تم تسجيل الخروج بنجاح"));
  };

  const handleLangToggle = () => {
    const newLanguage = language === "ar" ? "en" : "ar";
    setReduxLanguage(newLanguage);
    console.log(`Current language: ${language}`);
    document.location.replace("/");
  };

  if (pathName == "/404" || pathName === "/delivery-details") {
    return null;
  }

  return (
    <div className="flex items-center justify-between h-[91px] w-full max-w-[1424px]">
      <header className="flex border-[#FAFAFA]  items-center  w-full justify-between h-[60px] max-w-[1424px]">
        <div className="self-stretch justify-start items-center gap-6 flex">
          <Link href="/" className="w-1/3 h-full">
            <Image
              src={`${accountSettings?.logo ?? "/Logo.png"}`}
              alt="logo"
              width={1920}
              height={1080}
              className="w-full h-full object-contain"
            />
          </Link>

          <div className="self-stretch justify-start items-center gap-3 flex">
            {selectedTable !== null ? (
              <div className="pl-1.5 pr-4 py-1.5 bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-2.5 flex text-nowrap">
                {selectedTable?.table_number ||
                  "Table number: " + selectedTable}
              </div>
            ) : (
              <div
                onClick={() => router.push("/tables")}
                className="pl-1.5 pr-4 py-1.5 bg-[#f9f9f9] rounded-[100px] justify-start items-center gap-2.5 flex cursor-pointer"
              >
                <div className="self-stretch px-3 py-3.5 bg-primary rounded-[100px] shadow justify-center items-center gap-2.5 flex">
                  <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-[-0px] absolute">
                      <Image
                        width={24}
                        height={24}
                        src="/noun.svg"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-black text-sm font-medium font-['K2D'] leading-[16.84px]">
                  {t("nav.bookTable")}
                </div>
              </div>
            )}
          </div>
        </div>

        {pathName == "/" && (
          <div className=" rounded-full h-[50px]  w-full max-w-[631.67px] pl-1 pr-1 bg-[#FAFAFA]  sm:mx-6 ">
            <div className="relative flex items-center py-1  w-full max-w-[631.67px]  h-full">
              <input
                type="text"
                className="h-[46px] w-full max-w-[621.67px]  pl-10 pr-14 my-1 outline-none rounded-full"
                placeholder={t("search")}
                onFocus={() => setFocused(true)}
                // onBlur={() => setFocused(false)}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <motion.div
                initial={{ x: -50 }}
                animate={{
                  x: focused
                    ? language === "ar"
                      ? -572
                      : 572
                    : language === "ar"
                    ? -8
                    : 8,
                }}
                transition={{ type: "spring", stiffness: 20, damping: 10 }}
                className={`w-9 h-9 flex items-center justify-center rounded-full absolute cursore-pointer ${
                  focused ? " bg-blue-100" : ""
                }`}
                onClick={handleSearchClick}
              >
                <SearchNormal size="18" color="rgb(158, 158, 158)" />
              </motion.div>
            </div>
          </div>
        )}

        <div className="justify-start items-center gap-3 flex">
          <div
            onClick={handleLangToggle}
            className="cursor-pointer w-[50px] h-[50px] p-2 bg-[#f9f9f9] rounded-[100px] justify-center items-center gap-2.5 flex"
          >
            <div className="text-black text-lg font-normal font-['K2D'] leading-snug">
              {language.toUpperCase()}
            </div>
          </div>
          <div className="justify-start items-start gap-2 flex">
            <div
              onClick={toggleCartPopup}
              className={`p-[5px] ${
                cart.length > 0 ? "bg-primary" : "bg-[#f9f9f9]"
              } rounded-[100px] justify-start items-center gap-1.5 flex`}
            >
              <div className="p-2 rounded-[100px] justify-center items-center gap-2.5 flex">
                <CartSvg color={`${cart.length > 0 ? "white" : "black"}`} />
                {cart.length > 0 ? (
                  <div className="w-6 h-6 cursor-pointer justify-center items-center flex text-white text-xl font-bold">
                    {cart.length}
                  </div>
                ) : null}
              </div>
            </div>
            {isCartPopupOpen && <CartPopup onClose={closeCartPopup} />}
            <div
              className={`self-stretch p-[5px] ${
                isAuth ? "bg-primary/10" : "bg-[#f9f9f9]"
              } rounded-[100px] justify-start items-center gap-1.5 flex`}
            >
              {isAuth ? (
                <div
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-2 rounded-[100px] justify-start items-start gap-2.5 flex"
                >
                  <div className="w-6 h-6 cursor-pointer justify-center items-center flex z-50">
                    <div className="w-7 h-7 relative justify-center items-center flex text-primary">
                      {user?.name?.substring(0, 2).toUpperCase()}
                      {showOptions && (
                        <div className="absolute w-24 h-12 text-center items-center bg-black rounded-xl text-white top-8">
                          <h1 onClick={handleLogout}>{t("nav.logout")}</h1>
                          <h1
                            onClick={() => {
                              router.push("/profile");
                              setShowOptions(false);
                            }}
                          >
                            {t("nav.profile")}
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="self-stretch cursor-pointer p-2.5 bg-primary rounded-[100px] justify-center items-center gap-2.5 flex">
                    <Link
                      href={"/log-in"}
                      className="text-white text-base font-normal font-['K2D'] leading-tight"
                    >
                      {t("nav.auth.login")}
                    </Link>
                  </div>
                  <div className="self-stretch cursor-pointer  p-2.5 bg-primary/10 rounded-[100px] justify-center items-center gap-2.5 flex">
                    <Link
                      href={"/sign-up"}
                      className="text-primary text-base font-normal font-['K2D'] leading-tight"
                    >
                      {t("nav.auth.register")}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
