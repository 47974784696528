import { useDispatch, useSelector } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
} from "../redux/slices/cartSlice";
import { motion } from "framer-motion";
import Link from "next/link";
import Image from "next/image";
import { Add, Minus } from "iconsax-react";
import { setCouponCode } from "@/redux/slices/orderSlice";
import { currencyFormat } from "@/Utils/commons";
import { useTranslation } from "@/Utils/lanHook";
import { useSearchParams } from "next/navigation";

function CartPopup({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queries = useSearchParams();
  const cart = useSelector((state) => state.cart.cart);

  const handleAdd = (index) => {
    const item = cart[index];
    const itemId = item.offer_id
      ? item.offer_id
      : item.order_details[0].account_product_detail_id;
    dispatch(incrementQuantity(itemId));
  };

  const handleSubtract = (index) => {
    const item = cart[index];
    const itemId = item.offer_id
      ? item.offer_id
      : item.order_details[0].account_product_detail_id;

    dispatch(decrementQuantity(itemId));
  };

  const closePopup = () => {
    dispatch(setCouponCode(null));
    onClose();
  };

  const calculateTotalCartPrice = () => {
    return cart.reduce((total, order) => {
      if (order.order_details[0]?.offer_id) {
        return (
          total +
          order.order_details[0].unit_price * order.order_details[0].quantity
        );
      } else {
        return total + order.order_details[0].quantity_total;
      }
    }, 0);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.5 }}
      className="fixed inset-y-0 right-0 flex items-center justify-center bg-opacity-75 z-50"
    >
      <div className="bg-white relative pb-24 rounded-lg shadow-lg mx-4 mt-2 w-[378px] h-[90vh] overflow-y-auto no-scrollbar">
        <div className="flex flex-col gap-2 justify-between items-start px-4 py-2 h-28 rounded-t-lg">
          <button
            onClick={closePopup}
            className="bg-gray-200 p-3 m-2 rounded-full hover:p-[14px]"
          >
            <Image
              width={16}
              height={16}
              alt="Close"
              src="/Vector.svg"
              className="w-4 h-4"
            />
          </button>
          <h2 className="text-lg font-bold m-2">{t("cart.cart")}</h2>
        </div>

        <div className="p-4">
          {cart.length === 0 ? (
            <p className="text-center text-gray-500">{t("cart.empty")}</p>
          ) : (
            cart.map((order, index) => (
              <div
                key={index}
                className="border-b border-gray-300 flex flex-col gap-2 py-2"
              >
                <div className="flex items-center justify-between gap-2">
                  {/* Display image */}
                  <div className="flex items-center">
                    <Image
                      src={
                        order.order_details[0]?.offer_id
                          ? order.order_details[0].image
                          : order.order_details[0]?.image
                      }
                      width={1920}
                      height={1080}
                      alt="item"
                      className="w-[77px] h-[56px] rounded-md"
                    />
                    <div className="flex flex-col">
                      {/* Display name */}
                      <p className="text-base font-semibold leading-[19.25px]">
                        {order.order_details[0]?.offer_id
                          ? order.order_details[0].name
                          : order.order_details[0]?.name}
                      </p>
                      {/* Display unit price */}
                      <p className="text-base opacity-50 font-medium leading-[19.25px] mt-1">
                        {order.order_details[0]?.offer_id
                          ? order.order_details[0].unit_price
                          : order.order_details[0]?.unit_price}{" "}
                        {currencyFormat()}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {/* Decrement button */}
                    <button
                      className="w-8 h-8 bg-gray-200 rounded-lg flex items-center justify-center"
                      onClick={() => handleSubtract(index)}
                    >
                      <Minus size="18" color="black" />
                    </button>
                    {/* Display quantity */}
                    <span className="text-lg">
                      {order.order_details[0]?.offer_id
                        ? order.order_details[0].quantity
                        : order.order_details[0]?.quantity}
                    </span>
                    {/* Increment button */}
                    <button
                      className="w-8 h-8 bg-gray-200 rounded-lg flex items-center justify-center"
                      onClick={() => handleAdd(index)}
                    >
                      <Add size="18" color="black" />
                    </button>
                  </div>
                </div>
                {/* Product extra options, applies only for products */}
                {!order.order_details[0]?.offer_id &&
                  order.order_details[0].order_details_options.length > 0 && (
                    <div className="mt-2 flex gap-3">
                      <span className="flex flex-row flex-wrap gap-1 p-2 rounded-md w-full bg-[#F5F3F3]">
                        {order.order_details[0].order_details_options.map(
                          (option, idx) => (
                            <span
                              key={idx}
                              className="text-sm leading-[16.84px] whitespace-nowrap text-[#A1A1A1]"
                            >
                              {option.option_extra_name}{" "}
                              <span className="text-sm font-semibold leading-[16.84px] text-[#A1A1A1]">
                                {currencyFormat()} - {option.unit_price}
                              </span>
                            </span>
                          )
                        )}
                      </span>
                    </div>
                  )}
                {/* Total price display */}
                <div className="h-2.5 mb-3 justify-start items-start gap-2.5 inline-flex">
                  <span className="text-[#a1a1a1] text-sm font-medium leading-[16.84px]">
                    {t("total")}:
                  </span>
                  <span className="text-[#a1a1a1] text-sm font-semibold leading-[16.84px]">
                    {order.order_details[0]?.offer_id
                      ? order.order_details[0].unit_price *
                        order.order_details[0].quantity
                      : order.order_details[0]?.quantity_total}{" "}
                    {currencyFormat()}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>

        {cart.length > 0 && (
          <div className="fixed bottom-10 right-7 h-24 z-30 w-[356px] pl-[15px] pr-2 pt-3.5 pb-2 bg-white rounded-xl shadow-xl flex-col justify-center items-start gap-4 inline-flex">
            <div className="self-stretch h-[11px] flex-col justify-start items-start gap-[17px] flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#8d8d8d] text-base font-medium leading-tight">
                  {t("total")}:
                </div>
                <div className="text-black text-base font-semibold leading-tight">
                  {calculateTotalCartPrice()} {currencyFormat()}
                </div>
              </div>
            </div>
            <Link href={`/order?${queries}`}>
              <button
                onClick={closePopup}
                className="rounded-md hover:bg-white hover:text-primary text-base text-white h-[47px] bg-primary font-semibold box-border border-2 border-primary w-[333px]"
              >
                {t("checkOut")}
              </button>
            </Link>
            <div className="w-[333px] px-[134px] rounded-lg justify-center items-center gap-2.5 inline-flex"></div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default CartPopup;
