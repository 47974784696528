"use client";

import { useState } from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import { SearchNormal } from "iconsax-react";
import { useSelector } from "react-redux";
import { usePathname } from "next/navigation";
import { useLanguage, useTranslation } from "@/Utils/lanHook";

const HeaderPhon = () => {
  const { t } = useTranslation();
  const { language, setReduxLanguage } = useLanguage();
  const pathName = usePathname();
  const { accountSettings } = useSelector(
    (state) => state.accountPages || null
  );
  const [focused, setFocused] = useState(false);

  const handleLangToggle = () => {
    const newLanguage = language === "ar" ? "en" : "ar";
    setReduxLanguage(newLanguage);
    console.log(`Current language: ${language}`);
    document.location.replace("/");
  };

  if (pathName === "/404" || pathName === "/delivery-details") {
    return null;
  }

  return (
    <div className="w-full h-[10%] py-2 bg-white flex justify-between items-center gap-2">
      {/* Logo */}
      <img
        src={`${accountSettings?.logo ?? "/Logo.png"}`}
        alt="logo"
        width={1920}
        height={1080}
        className="w-2/12 h-2/12 object-contain"
      />

      {/* Search Bar */}
      {pathName == "/" && (
        <div className="flex-grow flex items-center gap-2">
          <div className="relative flex-grow rounded-full bg-[#FAFAFA] p-1">
            <input
              type="text"
              className="w-full h-10 pl-12 pr-12 outline-none rounded-full text-sm md:text-base bg-white"
              placeholder={t("search")}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <motion.div
              initial={{ left: "12px" }}
              animate={
                focused ? { left: "calc(100% - 48px)" } : { left: "12px" }
              }
              transition={{ type: "spring", stiffness: 20, damping: 10 }}
              className={`absolute top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full ${
                focused ? "bg-blue-100" : ""
              }`}
            >
              <SearchNormal size="18" color="rgb(158, 158, 158)" />
            </motion.div>
          </div>
        </div>
      )}

      {/* Language Button */}
      <div
        onClick={handleLangToggle}
        className="flex-shrink-0 cursor-pointer w-[50px] h-[50px] p-2 bg-[#f9f9f9] rounded-full flex items-center justify-center"
      >
        <div className="text-black text-lg font-normal">
          {language.toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default HeaderPhon;
