"use client";

import Image from "next/image";
import { motion } from "framer-motion";
import { Add, Minus } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { incrementQuantity, decrementQuantity } from "@/redux/slices/cartSlice";
import Link from "next/link";
import { setCouponCode } from "@/redux/slices/orderSlice";
import { useTranslation } from "@/Utils/lanHook";
import { currencyFormat } from "@/Utils/commons";
import { useSearchParams } from "next/navigation";

function CartPhone({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queries = useSearchParams();
  const cart = useSelector((state) => state.cart.cart);

  const handleAdd = (index) => {
    const item = cart[index];
    const itemId = item.offer_id
      ? item.offer_id
      : item.order_details[0].account_product_detail_id;
    dispatch(incrementQuantity(itemId));
  };

  const handleSubtract = (index) => {
    const item = cart[index];
    const itemId = item.offer_id
      ? item.offer_id
      : item.order_details[0].account_product_detail_id;

    dispatch(decrementQuantity(itemId));
  };

  const closePopup = () => {
    dispatch(setCouponCode(null));
    onClose();
  };

  const calculateTotalCartPrice = () => {
    return cart.reduce((total, order) => {
      if (order.order_details[0]?.offer_id) {
        return (
          total +
          order.order_details[0].unit_price * order.order_details[0].quantity
        );
      } else {
        return total + order.order_details[0].quantity_total;
      }
    }, 0);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={{ duration: 0.5 }}
      drag="y" // تفعيل السحب في الاتجاه الرأسي
      dragConstraints={{ top: 0, bottom: 100 }} // قيود السحب بحيث لا يتجاوز حداً معيناً
      onDragEnd={(event, info) => {
        if (info.offset.y > 100) {
          // شرط اغلاق الـ Popup اذا تم سحبها لأكثر من 100 بكسل
          closePopup();
        }
      }}
      className="fixed inset-x-0 bottom-0  z-50 bg-white   rounded-t-lg shadow-lg overflow-hidden h-[80vh]"
    >
      <div className="flex flex-col  gap-2 justify-between items-start px-4  rounded-t-lg border-b">
        <button
          onClick={closePopup}
          className="bg-gray-200 p-3 m-2 rounded-full hover:p-[14px]"
        >
          <Image
            width={16}
            height={16}
            alt="Vector"
            src="/Vector.svg"
            className="w-4 h-4"
          />
        </button>
        <h2 className="text-lg font-bold m-2">{t("cart.cart")}</h2>
      </div>
      <div className="p-4 overflow-y-auto pb-40 h-[calc(80vh-56px)] no-scrollbar">
        {cart.length === 0 ? (
          <p className="text-center text-gray-500">{t("cart.empty")}</p>
        ) : (
          cart.map((order, index) => (
            <div
              key={index}
              className="border-b border-gray-300 flex flex-col gap-2 py-2"
            >
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <Image
                    src={order.order_details[0].image}
                    width={1920}
                    height={1080}
                    alt="product"
                    className="w-[77px] h-[56px] rounded-md"
                  />
                  <div className="flex flex-col">
                    <p className="text-base font-semibold leading-[19.25px]">
                      {order.order_details[0].name}
                    </p>
                    <p className="text-base opacity-50 font-medium leading-[19.25px] mt-1">
                      {order.order_details[0].quantity_total} {currencyFormat()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className="w-8 h-8 bg-gray-200  rounded-lg flex items-center justify-center"
                    onClick={() => handleSubtract(index)}
                  >
                    <Minus size="18" color="black" />
                  </button>
                  <span className="text-lg">
                    {order.order_details[0].quantity}
                  </span>
                  <button
                    className="w-8 h-8 bg-gray-200  rounded-lg flex items-center justify-center"
                    onClick={() => handleAdd(index)}
                  >
                    <Add size="18" color="black" />
                  </button>
                </div>
              </div>
              <div className="mt-2 flex gap-3">
                {order.order_details[0].order_details_options.length > 0 && (
                  <span className="flex flex-row flex-wrap gap-1 p-2 rounded-md w-full bg-[#F5F3F3]">
                    {order.order_details[0].order_details_options.map(
                      (option, idx) => (
                        <span
                          key={idx}
                          className="text-sm leading-[16.84px] whitespace-nowrap text-[#A1A1A1]"
                        >
                          {option.option_extra_name}{" "}
                          <span className="text-sm font-semibold leading-[16.84px] text-[#A1A1A1]">
                            {option.unit_price} {currencyFormat()} -
                          </span>
                        </span>
                      )
                    )}
                  </span>
                )}
              </div>
            </div>
          ))
        )}
        {cart.length > 0 && (
          <div className="fixed bottom-0 w-full max-w-[1000] right-0 p-4 bg-white rounded-lg shadow-xl">
            <div className="flex w-full justify-between items-center">
              <span className="text-[#8d8d8d] text-base font-medium">
                {t("total")}:
              </span>
              <span className="text-black text-base font-semibold">
                {calculateTotalCartPrice()} {currencyFormat()}
              </span>
            </div>
            <Link className="w-full" href={`/order?${queries}`}>
              <button
                onClick={closePopup}
                className="mt-4 w-full bg-primary text-white py-2 rounded-md hover:bg-white hover:text-primary border-2 border-primary"
              >
                {t("checkOut")}
              </button>
            </Link>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default CartPhone;
