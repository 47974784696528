import { buildHttpResponse } from "@/network/networkUtils";
import { toast } from "react-toastify";
import { setUserData, signUp } from "@/redux/slices/authSlice";
import { store } from "@/redux/store";
import { getSecureItem } from "@/Utils/secureStorage";

export const loginServices = async (data) => {
  try {
    const response = await buildHttpResponse({
      method: "POST",
      endPoint: "login",
      body: data,
    });

    const { success, result, message } = response.data;

    if (success) {
      localStorage.setItem("phone", result.phone);
      toast.success(message);
    }
    return true;
  } catch (error) {
    console.log(error);
    toast.error(error.message ?? response.message);
    return false;
  }
};

export const signUpServices = async (data) => {
  try {
    const response = await buildHttpResponse({
      method: "POST",
      endPoint: "sign_in",
      body: data,
    });

    const { success, result, message } = response.data;
    if (success) {
      localStorage.setItem("phone", result.phone);
      toast.success(message);
      return true;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message ?? response.message);
    return false;
  }
};

export const verifyOTP = async (data) => {
  const response = await buildHttpResponse({
    method: "POST",
    endPoint: "verify-otp",
    body: data,
  });

  const { success, result, message } = response.data;
  if (success) {
    store.dispatch(setUserData({ user: result }));
    localStorage.removeItem("phone");
    toast.success(message);
  }
};

export const logOutServices = async () => {
  await buildHttpResponse({
    method: "GET",
    endPoint: "logout",
    headers: {
      Authorization: `Bearer ${await getSecureItem("user")?.data?.token}`,
    },
  });
};
